import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />
};

const navConfig = [
  // Report
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      {
        title: 'Dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.general.app,
        items: [
          {
            title: 'App',
            href: PATH_APP.general.app
          },
          {
            title: 'Analytics',
            href: PATH_APP.general.analytics
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Management',
        icon: ICONS.components,
        href: PATH_APP.general.app,
        items: [
          {
            title: 'Vehicle',
            href: PATH_APP.general.app
          },
          {
            title: 'Geofence',
            href: PATH_APP.general.analytics
          }
        ]
      }
    ]
  },
  {
    subheader: 'Report',
    items: [
      {
        title: 'Report',
        icon: ICONS.elements,
        href: PATH_APP.report.engine,
        items: [
          {
            title: 'Engine',
            href: PATH_APP.report.engine
          },
          {
            title: 'Gps',
            href: PATH_APP.report.routes
          },
          // {
          //   title: 'SecondReport',
          //   href: PATH_APP.report.secondReport
          // },
          {
            title: 'Routes',
            href: PATH_APP.report.routes
          },
          // {
          //   title: 'Travel',
          //   href: PATH_APP.report.travel
          // },
          {
            title: 'Ignition ',
            href: PATH_APP.report.ignition
          },
          {
            title: 'Test',
            href: PATH_APP.report.test
          }
        ]
      }
    ]
  }
];

export default navConfig;
