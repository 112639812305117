/* eslint-disable prettier/prettier */
// import axios from 'src/utils/axios';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
// import SelectInput from '@material-ui/core/Select/SelectInput';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11,
  getTestData: [],
  getTestLData: []
};

const slice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
      //console.log('cammeeesss' + JSON.stringify(action.payload));
    },
    // GET POST INFINITE
    getTest(state, action) {
      state.isLoading = false;
      state.getTestData = action.payload;
      //console.log('cammeeesss' + JSON.stringify(action.payload));
    },
    getTestlocal(state, action) {
      state.isLoading = false;
      state.getTestLData = action.payload;
      //console.log('cammeeesss' + JSON.stringify(action.payload));
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPostsInitial(
  date,
  month,
  year,
  toDate,
  toMonth,
  toYear,
  Filter,
  clientID
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //dispatch(slice.actions.getPostsInitial());
    // console.log(Filter, 'Filterrrrrrrrrrrrrrr')
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          date: date,
          month: month,
          year: year,
          toDate: toDate,
          toMonth: toMonth,
          toYear: toYear,
          companyId: 0
          // clientID: clientID
        })
      };

      fetch('http://15.184.158.127:8812/api/v1/shiftListRange', requestOptions)
        .then((response) => response.json())
        .then((data) => dispatch(slice.actions.getPostsInitial(data)));
    } catch (error) {
      //console.log('res' + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getTestIn() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     //dispatch(slice.actions.getPostsInitial());
//     // console.log(Filter, 'Filterrrrrrrrrrrrrrr')
//     try {
//       const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           driver_id: '11633',
//           trip_id: '1544186',
//           driver_app_version: '1.1.6',
//           locations:
//             '11.026815265716126,77.0220284497437|11.026761851265904,77.02192023446509|11.026709054647169,77.02180984153499|11.026655546786898,77.02169796146352|11.026602203058323,77.0215864245765|11.026554391047268,77.0214734167229|11.02650706159237,77.02136412318474|',
//           status: 'A',
//           travel_status: '2',
//           device_token: 'cac831f44b2ea8d8',
//           org_device_token:
//             'diaz96__RNuPFeB4EYio6t:APA91bHOdxfZ8aQKAVXSS7CprvcPiaep2NUbog4D9yw6oLyItVOHU_LYoukA-Yk5nwawbKFoyHgYR30h_xtjv7cMLvtLnubmWQz2aUR1KuAIod_Ytwi7LHXfDQE8bZprTAUsFo7kngfn',
//           device_type: '1',
//           bearing: 246.32986450195312,
//           drop_location: '',
//           drop_lat: '0.0',
//           drop_lng: '0.0',
//           device_longitude: '0.0',
//           device_latitude: '0.0',
//           above_min_km: '1',
//           driver_cid: 194,
//           driver_waiting_time: '00:08:52',
//           waiting_cost: '12.0'
//         })
//       };

//       fetch('http://ndssl.limor.us:8850/api/v1/locationhistory', requestOptions)
//         .then((response) => response.json())
//         .then((data) => dispatch(slice.actions.getTest(data)));
//     } catch (error) {
//       //console.log('res' + error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getTestIn(values) {
  console.log(values);
  const setV = {
    driver_id: '11633',
    trip_id: '1544186',
    driver_app_version: '1.1.6',
    locations:
      '11.026815265716126,77.0220284497437|11.026761851265904,77.02192023446509|11.026709054647169,77.02180984153499|11.026655546786898,77.02169796146352|11.026602203058323,77.0215864245765|11.026554391047268,77.0214734167229|11.02650706159237,77.02136412318474|',
    status: 'A',
    travel_status: '2',
    device_token: 'cac831f44b2ea8d8',
    org_device_token:
      'diaz96__RNuPFeB4EYio6t:APA91bHOdxfZ8aQKAVXSS7CprvcPiaep2NUbog4D9yw6oLyItVOHU_LYoukA-Yk5nwawbKFoyHgYR30h_xtjv7cMLvtLnubmWQz2aUR1KuAIod_Ytwi7LHXfDQE8bZprTAUsFo7kngfn',
    device_type: '1',
    bearing: 246.32986450195312,
    drop_location: '',
    drop_lat: '0.0',
    drop_lng: '0.0',
    device_longitude: '0.0',
    device_latitude: '0.0',
    above_min_km: '1',
    driver_cid: 194,
    driver_waiting_time: '00:08:52',
    waiting_cost: '12.0'
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://ndssl.limor.us/api/v1/locationhistory',
        setV,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch(slice.actions.getTest(response.data));
      console.log(response.data);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTestL(values) {
  console.log(values);
  const setV = {
    dropLng: '0.0',
    skip_fav: '0',
    address:
      '105, Villankurichi Rd, B.R. Puram Industrial Estate, Aerodrome Post, Coimbatore, Tamil Nadu 641014, India. ',
    longitude: '77.01943631504321',
    place_id: '',
    route_polyline: '',
    motor_model: '1',
    city_name: '',
    latitude: '11.031732438211266',
    dropLat: '0.0',
    passenger_id: '308',
    passenger_app_version: '1.0'
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://ndssl.limor.us:8080/test',
        {
          headers: {
            'Content-Type': 'application/json',
             'Authorization': 'authkey ${authkey}'
          }
        }
      );
      // dispatch(slice.actions.getTestlocal(response.data));
      console.log(response);
    } catch (error) {
      console.log(error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}
