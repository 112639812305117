/* eslint-disable prettier/prettier */
// import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
// import SelectInput from '@material-ui/core/Select/SelectInput';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'deviceProfile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getProfile(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
      //console.log('cammeeesss' + JSON.stringify(action.payload));
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getProfile(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //dispatch(slice.actions.deviceProfile());
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: userId })
      };

      fetch(
        'http://15.184.158.127:8812/api/v1/getDeviceInfoByID',
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => dispatch(slice.actions.getProfile(data)));
    } catch (error) {
      //console.log('res' + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
