import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.report.engine,
      component: lazy(() => import('src/views/Reports'))
    },
    {
      exact: true,
      path: PATH_APP.report.secondReport,
      component: lazy(() => import('src/views/secondReport'))
    },
    {
      exact: true,
      path: PATH_APP.report.ramgps,
      component: lazy(() => import('src/views/ramGps'))
    },
    {
      exact: true,
      path: PATH_APP.report.fuel,
      component: lazy(() => import('src/views/Reports/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.report.travelSheet,
      component: lazy(() => import('src/views/Reports'))
    },
    {
      exact: true,
      path: PATH_APP.report.travel,
      component: lazy(() => import('src/views/Reports'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.report.root} />
    },
    {
      exact: true,
      path: PATH_APP.report.history,
      component: lazy(() => import('src/views/mapComponent/history'))
    },
    {
      exact: true,
      path: PATH_APP.report.test,
      component: lazy(() => import('src/views/Test'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
